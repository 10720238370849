import { AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user.service';

export class UserValidators {

  static emailUniqueValidator(userService: UserService, userId: string): AsyncValidatorFn | null {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const email = control.value;
      return userService.findByEmail(email).pipe(
        map(user => {
          const emailExists = user != null && (user.uuid !== userId);
          return emailExists ? { emailExists: true } : null;
        })
      );
    }
  }

  static usernameUniqueValidator(userService: UserService, userId: string): AsyncValidatorFn | null {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const username = control.value;
      return userService.findByUsername(username).pipe(
        map(user => {
          const usernameExists = user != null && (user.uuid !== userId);
          return usernameExists ? { usernameExists: true } : null;
        })
      );
    }
  }
}
