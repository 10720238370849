<mat-toolbar>
  <button mat-icon-button
          matTooltip="{{ 'admin.navbar.menu-toggle' | translate }}"
          class="menu-toggle"
          (click)="menuSideNav.toggle()">
    <mat-icon>menu</mat-icon><span *ngIf="!isMobile">{{ 'title' | translate }}</span>
  </button>
  <span class="toolbar-item-spacer"></span>
  <button mat-icon-button
          matTooltip="{{ 'admin.navbar.theme' | translate }}"
          [matMenuTriggerFor]="themeMenu">
    <mat-icon>color_lens</mat-icon>
  </button>
  <mat-menu #themeMenu
            [overlapTrigger]="false"
            yPosition="below">
    <div mat-menu-item
         class="custom-menu">
      <div class="theme-preview theme-green"
           (click)="switchTheme(Theme.GREEN)">
      </div>
    </div>
    <div mat-menu-item
         class="custom-menu">
      <div class="theme-preview theme-blue"
           (click)="switchTheme(Theme.BLUE)">
      </div>
    </div>
  </mat-menu>
  <button mat-icon-button
          matTooltip="{{ 'admin.navbar.language' | translate }}"
          [matMenuTriggerFor]="languageMenu">
    <mat-icon>flag</mat-icon>
  </button>
  <mat-menu #languageMenu
            [overlapTrigger]="false"
            yPosition="below">
    <div mat-menu-item
         class="custom-menu"
         (click)="switchLanguage('ro')">
      <img src="assets/img/flag_ro.png" />
    </div>
    <div mat-menu-item
         class="custom-menu"
         (click)="switchLanguage('en')">
      <img src="assets/img/flag_us.png" />
    </div>
  </mat-menu>
  <button #notificationMenu
          mat-icon-button
          matTooltip="{{ 'admin.navbar.notifications' | translate }}"
          (click)="notificationSideNav.toggle()">
    <mat-icon [matBadge]="notifications.length"
              [matBadgeHidden]="notifications.length === 0"
              matBadgeOverlap="false">
      notifications
    </mat-icon>
  </button>
  <button mat-icon-button
          matTooltip="{{ 'admin.navbar.account' | translate }}"
          [matMenuTriggerFor]="userAccountMenu">
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #userAccountMenu
            class="user-menu"
            [overlapTrigger]="false"
            yPosition="below">
    <button mat-menu-item>
      <mat-icon>person</mat-icon><span>{{ 'admin.navbar.account' | translate }}</span>
    </button>
    <button mat-menu-item
            routerLink="/core/account/settings">
      <mat-icon>settings</mat-icon><span>{{ 'navbar.user-menu.settings' | translate }}</span>
    </button>
    <button mat-menu-item>
      <mat-icon>help</mat-icon><span>{{ 'admin.navbar.help' | translate }}</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
            (click)="logout()">
      <mat-icon>exit_to_app</mat-icon><span>{{ 'navbar.user-menu.logout' | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
<mat-divider></mat-divider>
<div class="sidenav-parent">
  <mat-sidenav-container class="app-sidenav-container">
    <mat-sidenav #menuSideNav
                 [mode]="isMobile ? 'over': 'side'"
                 class="app-sidenav"
                 [class.mat-elevation-z4]="true"
                 [opened]="!isMobile">
      <div class="user-profile">
        <mat-icon>person</mat-icon>
        <div>{{ authenticatedUser }}</div>
      </div>
      <mat-nav-list>
        <ng-container *ngFor="let menuItem of menu">
          <ng-container *ngIf="menuItem.children.length == 0">
            <mat-list-item [routerLink]="menuItem.link"
                           [queryParams]="menuItem.queryParams">
              <mat-icon>{{ menuItem.icon }}</mat-icon><span>{{ menuItem.key | translate }}</span>
            </mat-list-item>
          </ng-container>
          <ng-container *ngIf="menuItem.children.length > 0">
            <mat-expansion-panel [class.mat-elevation-z0]="true">
              <mat-expansion-panel-header>
                <mat-icon>{{ menuItem.icon }}</mat-icon><span>{{ menuItem.key | translate }}</span>
              </mat-expansion-panel-header>
              <mat-nav-list>
                <a mat-list-item
                   *ngFor="let subMenuItem of menuItem.children"
                   [routerLink]="subMenuItem.link"
                   [queryParams]="subMenuItem.queryParams">
                  <mat-icon>{{ subMenuItem.icon }}</mat-icon><span>{{ subMenuItem.key | translate }}</span>
                </a>
              </mat-nav-list>
            </mat-expansion-panel>
          </ng-container>
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <!-- <div class="app-sidenav-content"> -->
      <router-outlet></router-outlet>
      <!-- </div> -->
    </mat-sidenav-content>
  </mat-sidenav-container>
  <mat-sidenav-container class="notificatons-sidenav-container"
                         autosize="true">
    <mat-sidenav #notificationSideNav
                 class="notification-sidenav"
                 mode="over"
                 position="end">
      <app-notifications [(notifications)]="notifications"></app-notifications>
    </mat-sidenav>
  </mat-sidenav-container>
</div>