import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from 'src/app/shared/model/user';
import { PageRequest } from 'src/app/shared/model/page/page-request';
import { PageResult } from 'src/app/shared/model/page/page-result';
import { map } from 'rxjs/operators';
import { UserFilter } from 'src/app/shared/model/filter/user-filter';
import { PasswordChange } from 'src/app/shared/model/password-change';
import { HttpUtils } from 'src/app/shared/services/common/http-utils';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(private readonly http: HttpClient) { }

  findByUsername(username: string): Observable<User> {
    return this.http.get<User>(`api/users/username/${username}`);
  }

  findById(userUUID: string): Observable<User> {
    return this.http.get<User>(`api/users/${userUUID}`);
  }

  findByFilter(userFilter: UserFilter, pageRequest: PageRequest): Observable<PageResult<User>> {
    const httpParams = HttpUtils.createHttpParams(userFilter, pageRequest);
    return this.http.get<PageResult<User>>(`api/users`, { params: httpParams });
  }

  findByEmail(email: string): Observable<User> {
    const httpParams = HttpUtils.createHttpParams({ email: email });
    return this.http
      .get<PageResult<User>>(`api/users`, { params: httpParams })
      .pipe(map(page => page.content[0]));
  }

  create(user: User): Observable<User> {
    return this.http.post<User>(`api/users`, user);
  }

  update(userUUID: string, user: User): Observable<User> {
    return this.http.put<User>(`api/users/${userUUID}`, user);
  }

  deleteById(userUUID: string): Observable<void> {
    return this.http.delete<void>(`api/users/${userUUID}`);
  }

  sendPasswordResetEmail(email: string): Observable<void> {
    return this.http.post<void>(`api/users/password/reset/email`, email);
  }

  changePassword(userUUID: string, passwordChange: PasswordChange): Observable<void> {
    return this.http.put<void>(`api/users/${userUUID}/password`, passwordChange);
  }

  exportToExcel(pageRequest: PageRequest): Observable<any> {
    return this.http.get<any>(`api/users/export/excel`, {
      params: pageRequest as any,
      responseType: 'blob' as 'json'
    });
  }
}
