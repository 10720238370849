import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CourierOptions } from 'src/app/shared/model/courier-options';
import { OidcConfig } from '../../model/oidc-config';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  constructor(private readonly http: HttpClient) { }

  getCourierOptions(): Observable<CourierOptions> {
    return this.http.get<CourierOptions>(`api/config/courier-options`);
  }

  getPublicUrl(): Observable<string> {
    return this.http.get<string>(`api/config/public-url`, { responseType: 'text' as 'json' });
  }

  getWebsocketUrl(): Observable<string> {
    return this.http.get<string>(`api/config/websocket-url`, { responseType: 'text' as 'json' });
  }

  getOidcConfig(): Observable<OidcConfig> {
    return this.http.get<OidcConfig>(`api/config/oidc`);
  }
}
