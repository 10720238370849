import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private readonly translateService: TranslateService) { }

  getErrorMessage(validationErrors: ValidationErrors): string[] {
    return Object.keys(validationErrors).map(errorName => {
      const validationError = validationErrors[errorName];
      switch (errorName) {
        case 'required':
          return this.translateService.instant(`global.messages.error.field-required`);
        case 'notEqual':
          return this.translateService.instant(`global.messages.error.field-not-match`);
        case 'emailExists':
          return this.translateService.instant(`global.messages.error.email-exists`);
        case 'usernameExists':
          return this.translateService.instant(`global.messages.error.username-exists`);
        case 'phoneNumberInvalid':
          return this.translateService.instant(`global.messages.error.phone-number-invalid`);
        case 'minlength':
          return this.translateService.instant(`global.messages.error.min-length`, { minLength: validationError['requiredLength'] });
        case 'maxlength':
          return this.translateService.instant(`global.messages.error.max-length`, { maxLength: validationError['requiredLength'] });
        case 'min':
          return this.translateService.instant(`global.messages.error.min`, { min: validationError['min'] });
        case 'max':
          return this.translateService.instant(`global.messages.error.max`, { max: validationError['max'] });
        default:
          return this.translateService.instant('global.messages.error.field-invalid');
      }
    });
  }

  translateAndConcatErrorMessages(validationErrors: ValidationErrors): string {
    return this
      .getErrorMessage(validationErrors)
      .join(' ');
  }
}
