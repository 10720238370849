<ng-container *ngIf="notifications.length === 0">
  <div class="notification">
    <div>
      <div class="notification-header">
        <div class="notification-title">
          {{ 'global.notifications.no-notifications' | translate }}
        </div>
      </div>
      <div class="notification-content">
        <div class="notification-details">
        </div>
      </div>
      <div class="notification-footer"></div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="notifications.length > 0">
  <div *ngFor="let notification of notifications"
       class="notification"
       [ngClass]="notification.type"
       [ngClass]="notification.level"
       [ngSwitch]="notification.type">

    <div *ngSwitchCase="NotificationType.AWB_STATUS_UPDATE">
      <div class="notification-header">
        <div class="notification-title">{{ 'global.notifications.type.AWB_STATUS_UPDATE.title' | translate }}</div>
        <div class="close-btn"
             (click)="markRead(notification)">×
        </div>
      </div>
      <div class="notification-content">
        <div class="notification-details">
          <div class="awb-number">{{ notification.data.awbNumber }}</div>
          <div class="status">{{ notification.data.status }}</div>
          <div class="date">{{ notification.date | date: 'dd/MM/yyyy HH:mm' }}</div>
        </div>
      </div>
      <div class="notification-footer"></div>
    </div>

  </div>
</ng-container>