import { HttpParams } from '@angular/common/http';

export class HttpUtils {

  static createHttpParams(...values: any[]): HttpParams {
    let httpParams = new HttpParams();
    values.forEach(value => {
      Object.keys(value)
        .filter(key => value[key] != null)
        .forEach(key => httpParams = httpParams.append(key, value[key]));
    });
    return httpParams;
  }
}
