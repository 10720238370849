import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { LoggingService } from '../common/logging.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {

  constructor(@Inject(Injector) private readonly injector: Injector) { }

  handleError(error: any): void {
    this.injector.get(LoggingService).error(error);
  }

}
