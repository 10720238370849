import { FormGroup, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {

  static equal(controlName: string, matchingControlName: string): ValidationErrors | null {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.notEqual) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ notEqual: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  static match(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.notEqual) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ notEqual: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  static positive(control: AbstractControl): ValidationErrors | null {
    const number: number = Number(control.value);
    if (number <= 0) {
      return { positive: true };
    }
    return null;
  }

  static phoneNumber(): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      const phoneNumber = formControl.value;
      if (!phoneNumber) {
        return null;
      }
      const phoneNumberRegex: RegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (!phoneNumber.match(phoneNumberRegex)) {
        return { phoneNumberInvalid: true };
      }
      return null;
    };
  }

  static email(): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      const email = formControl.value;
      if (!email) {
        return null;
      }
      const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!email.match(emailRegex)) {
        return { emailInvalid: true };
      }
      return null;
    };
  }
}
