import { Injectable } from '@angular/core';
import { IMessage, RxStomp } from "@stomp/rx-stomp";
import { Observable, map } from 'rxjs';
import { AuthService } from '../common/auth.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  private rxStomp: RxStomp;

  constructor(private readonly authService: AuthService) { }

  configure(url: string): void {
    this.rxStomp = new RxStomp();
    this.rxStomp.configure({
      brokerURL: url,
      connectHeaders: {
        Authorization: 'Bearer ' + this.authService.accessToken
      },
      heartbeatIncoming: 0, // Interval in milliseconds to send heartbeats to the server (0 to disable)
      heartbeatOutgoing: 20000, // Interval in milliseconds to send heartbeats to the server (0 to disable)
      reconnectDelay: 200, // Interval in milliseconds to wait before attempting to reconnect (0 to disable auto-reconnect)
      beforeConnect: (stompClient: any): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
          const token = 'token';
          stompClient.connectHeaders = {
            Authorization: `Bearer ${token}`
          }
          resolve();
        });
      },
      debug: str => console.log(str)
    });
  }

  activate(): void {
    this.rxStomp.activate();

    this.watch<string>('/user/queue/heartbeat', false)
      .subscribe((msg: string) => console.log('Heartbeat: ' + msg))
    this.sendMessage('BauExpress Angular', "/app/ping");
  }

  stop(): void {
    this.rxStomp.deactivate().then();
  }

  watch<T>(topic: '/user/queue/heartbeat' | '/user/queue/notifications', json = true): Observable<T> {
    return this.rxStomp
      .watch({ destination: topic })
      .pipe(map((msg: IMessage) => json ? JSON.parse(msg.body) as T : msg.body as T));
  }

  sendMessage(message: string, destination: "/app/ping" | "none"): void {
    this.rxStomp.publish({
      destination: destination,
      body: message
    });
  }
}