import { AfterViewInit, Component, Injector } from "@angular/core";
import { NgControl } from "@angular/forms";
import { MatFormField } from "@angular/material/form-field";
import { filter } from "rxjs/operators";
import { ValidationService } from "../services/common/validation-service";
import { ControlContainer } from '@angular/forms';

@Component({
  selector: '[matErrorMessage]',
  template: '{{ error }}'
})
export class ValidationErrorMessageComponent implements AfterViewInit {

  error: string;

  constructor(
    private readonly injector: Injector,
    private readonly validationService: ValidationService,
    private readonly form: ControlContainer) { }

  public ngAfterViewInit(): void {
    // grab reference to MatFormField directive, where form control is accessible.
    const container = this.injector.get(MatFormField);
    const ngControl = container._control.ngControl as NgControl;

    // sub to the control's status stream
    ngControl.statusChanges
      .pipe(filter(status => status === 'INVALID'))
      .subscribe(() => this.showFirstError(ngControl));
      // this.form.statusChanges.subscribe(it => console.log(`status ${it}`));
      // this.form.valueChanges.subscribe(it => console.log(`value ${JSON.stringify(it)}`));
      // this.form.control.statusChanges.subscribe(it => console.log(`status control ${it}`));
      // this.form.control.valueChanges.subscribe(it => console.log(`value control ${it}`));

    this.form.statusChanges.pipe(filter(status => status === 'INVALID')).subscribe(status => {
      if (ngControl.control.status === 'INVALID' && this.form.dirty) {
        this.showFirstError(ngControl);
      }
    });
    if (ngControl.status === 'INVALID' && this.form.dirty) {
      this.showFirstError(ngControl);
    }
  }

  private showFirstError(ngControl: NgControl): void {
    // ngControl.control.markAsTouched();
    this.error = this.validationService.translateAndConcatErrorMessages(ngControl.control.errors);
  }
}