import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators'
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorResponse } from 'src/app/shared/model/error/error-response';
import { ToastService } from 'src/app/shared/services/common/toast.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
        }
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          const errorResponse: ErrorResponse = error.error;
          if (error.status !== 401) {
            console.log(error);
            if (errorResponse.visible) {
              this.toastService.warning(
                this.translateService.instant('global.notifications.error.title'),
                errorResponse.message,
                10000
              );
            }
          }
        }
        return throwError(error);
      }));
  }
}
