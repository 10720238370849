import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/model/user';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormHelper } from 'src/app/shared/services/common/form-helper';
import { ToastService } from 'src/app/shared/services/common/toast.service';
import { PasswordChange } from 'src/app/shared/model/password-change';
import { UserService } from 'src/app/features/users/services/user.service';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/common/auth.service';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {

  user: User;
  passwordChangeForm: FormGroup;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly formBuilder: FormBuilder,
    private readonly formHelper: FormHelper,
    private readonly userService: UserService,
    private readonly toastService: ToastService) { }

  ngOnInit(): void {
    this.authService.getUsername()
      .pipe(
        switchMap(username => this.userService.findByUsername(username)),
        tap(user => this.user = user),
        tap(() => this.passwordChangeForm = this.resetPasswordChangeForm())
      )
      .subscribe();
  }

  save(): void {
    FormHelper.markAsDirty(this.passwordChangeForm);
    if (this.passwordChangeForm.valid) {
      const passwordChange: PasswordChange = this.passwordChangeForm.value;
      this.userService
        .changePassword(this.user.uuid, passwordChange)
        .subscribe(() => this.toastService.showSuccessNotification());
    } else {
      this.formHelper.focusFirstErrorField();
    }
  }

  reset(): void {
    this.resetPasswordChangeForm();
  }

  private resetPasswordChangeForm(): FormGroup {
    return this.formBuilder.group({
      oldPassword: [null, Validators.required],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required]
    },
      { validator: CustomValidators.match('password', 'confirmPassword') });
  }

}
