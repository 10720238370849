import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Theme } from './theme';

@Injectable({ providedIn: 'root' })
export class ThemeService {

  private theme = new Subject<Theme>();

  constructor() { }

  getTheme(): Observable<Theme> {
    return this.theme.asObservable();
  }

  setTheme(theme: Theme): void {
    this.theme.next(theme);
  }

}
