import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  static readonly DEFAULT_TIMEOUT = 2000;

  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService) { }

  showSuccessNotification(): void {
    const title = this.translateService.instant('global.notifications.success.title');
    const message = this.translateService.instant('global.notifications.success.message');
    this.success(title, message);
  }

  showErrorNotification(): void {
    const title = this.translateService.instant('global.notifications.error.title');
    const message = this.translateService.instant('global.notifications.error.title');
    this.error(title, message);
  }

  success(title: string, message: string): void {
    this.toastr.success(message, title, {
      timeOut: ToastService.DEFAULT_TIMEOUT,
      toastClass: 'ngx-toastr',
      closeButton: true
    });
  }

  info(title: string, message: string): void {
    this.toastr.success(message, title, {
      timeOut: ToastService.DEFAULT_TIMEOUT,
      closeButton: true
    });
  }

  warning(title: string, message: string, timeOut?: number): void {
    this.toastr.warning(message, title, {
      timeOut: timeOut,
      toastClass: 'ngx-toastr',
      closeButton: true
    })
  }

  error(title: string, message: string): void {
    this.toastr.error(message, title, {
      timeOut: ToastService.DEFAULT_TIMEOUT,
      toastClass: 'toast-error',
      closeButton: true
    });
  }
}
