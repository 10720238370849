import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { PageNumberPipe } from './pipes/page-number.pipe';
import { BooleanLabelPipe } from './pipes/boolean-label.pipe';
import { AddressPipe } from './pipes/address.pipe';
import { TotalAmountPipe } from './pipes/total-amount.pipe';
import { DateTimePipe } from './pipes/date-time.pipe';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { SeparatorComponent } from './components/separator/separator.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlLocalized } from './components/mat-paginator-intl';
import { DeleteDialogComponent } from './components/dialogs/delete-dialog/delete-dialog.component';
import { ValidationErrorMessageComponent } from 'src/app/shared/util/validation-error-message.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AngularMaterialModule } from './angular-material.module';

@NgModule({
  declarations: [
    BackButtonComponent,
    SeparatorComponent,
    DateTimePipe,
    PageNumberPipe,
    BooleanLabelPipe,
    AddressPipe,
    TotalAmountPipe,
    DeleteDialogComponent,
    ValidationErrorMessageComponent,
    AddressFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ToastrModule.forRoot(),
    AngularMaterialModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ToastrModule,
    FormsModule,
    BackButtonComponent,
    SeparatorComponent,
    DateTimePipe,
    PageNumberPipe,
    BooleanLabelPipe,
    AddressPipe,
    TotalAmountPipe,
    ValidationErrorMessageComponent,
    AddressFormComponent
  ],
  providers: [
    {
      provide: MatPaginatorIntl, useClass: MatPaginatorIntlLocalized, deps: [TranslateService]
    }
  ]
})
export class SharedModule { }
