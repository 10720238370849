import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { URLPattern } from "./url-pattern";

export abstract class SecuredApiInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (URLPattern.isProtected(request)) {
      return this.doIntercept(request, next);
    }
    return next.handle(request);
  }

  abstract doIntercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>;
}
