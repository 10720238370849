import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { ThemeService } from './shared/theme/theme.service';
import { Theme } from './shared/theme/theme';
import { DOCUMENT } from '@angular/common';
import { pairwise, startWith } from 'rxjs/operators';
import { AuthService } from './shared/services/common/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'bauexpress';

  constructor(
    private readonly logger: NGXLogger,
    private readonly oauthService: OAuthService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document) {

    this.translate.addLangs(['en', 'ro'])
    this.translate.setDefaultLang('en');
    this.translate.use('ro');

    this.router.events.subscribe((event: Event) => this.handleRouterEvents(event));
    this.oauthService.events.subscribe(event => this.logger.debug(event));

    this.oauthService.tryLogin().then(res => {
      this.logger.debug(`Load discovery document: ${JSON.stringify(res)}`);
      this.authService.isDoneLoading$.next(true);
      this.authService.refresh();
    })
  }

  ngOnInit(): void {
    this.onThemeChanged();
    this.themeService.setTheme(Theme.GREEN);
  }

  private onThemeChanged(): void {
    this.themeService.getTheme()
      .pipe(
        startWith(undefined as Theme),
        pairwise()
      )
      .subscribe(([oldTheme, newTheme]) => {
        if (oldTheme) {
          const oldThemeClass = Theme.themeClass(oldTheme);
          this.document.body.classList.remove(oldThemeClass);
        }
        const newThemeClass = Theme.themeClass(newTheme);
        this.document.body.classList.add(newThemeClass);
      });
  }

  private handleRouterEvents(event: Event): void {
    if (event instanceof NavigationStart) {
    } else if (event instanceof NavigationEnd) {
    } else if (event instanceof NavigationCancel) {
    } else if (event instanceof NavigationError) {
    }
  }

}
