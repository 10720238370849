import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UrlSerializer } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { interceptorProviders } from './core/interceptors/_interceptors';
import { GlobalErrorHandler } from './shared/services/error/global-error-handler';
import { CustomUrlSerializer } from './shared/services/custom-url-serializer';
import { AngularMaterialModule } from './shared/angular-material.module';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { LoggerModule } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { applicationInitializerFactory } from './app-init';
import { AuthService } from './shared/services/common/auth.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    SharedModule,
    AppRoutingModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    BsDropdownModule.forRoot(),
    CoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularMaterialModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['api/'],
        sendAccessToken: true,
        customUrlValidation: url => publicUrls.every(publicUrl => url !== publicUrl.toLowerCase())
      }
    }),
    LoggerModule.forRoot({
      serverLoggingUrl: `${environment.baseUrl}/api/ng-logs`,
      level: environment.logLevel,
      serverLogLevel: environment.serverLogLevel
    })
  ],
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: APP_INITIALIZER, useFactory: applicationInitializerFactory, deps: [AuthService], multi: true },
    interceptorProviders
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// We need a factory, since localStorage is not available during AOT build time.
export function storageFactory() : OAuthStorage {
  return localStorage;
}

const publicUrls = [
  'api/config/oidc',
  'api/config/public-url'
]