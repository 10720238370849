import { UserRole } from "src/app/shared/model/user-role";

export const menu = [
  {
    id: 'customers',
    key: 'admin.customers.nav',
    icon: 'admin_panel_settings',
    link: '/core/customers',
    queryParams: {
      page: 0,
      size: 10
    },
    activeLinks: [
      '/core/customers/new',
      '/core/customers/:customerId'
    ],
    children: [],
    roles: [UserRole.ADMIN]
  },
  {
    id: 'adress-book',
    key: 'admin.address-book.nav',
    icon: 'person_pin',
    link: '/core/address-books',
    queryParams: {
      page: 0,
      size: 10
    },
    activeLinks: [
      '/core/address-books/new',
      '/core/address-books/:addressBookEntryId'
    ],
    children: [],
    roles: [UserRole.CUSTOMER]
  },
  {
    id: 'orders',
    key: 'admin.orders.nav',
    icon: 'reorder',
    link: '/core/awbs',
    queryParams: {
      page: 0,
      size: 10
    },
    activeLinks: [
      '/core/awbs/new',
      '/core/awbs/tracking'
    ],
    children: [],
    roles: [UserRole.ADMIN, UserRole.CUSTOMER]
  },
  {
    id: 'reports-operational',
    key: 'admin.reports.operational.title',
    icon: 'query_stats',
    link: '/core/reports/awbs',
    queryParams: {
      page: 0,
      size: 500
    },
    activeLinks: [],
    roles: [UserRole.ADMIN],
    children: []
  },
  // {
  //   id: 'reports',
  //   key: 'admin.reports.nav',
  //   icon: 'query_stats',
  //   link: '/core/reports',
  //   queryParams: {},
  //   activeLinks: [],
  //   roles: [UserRole.ADMIN],
  //   children: [
  //     {
  //       id: 'operational',
  //       key: 'admin.reports.operational.nav',
  //       icon: 'insights',
  //       link: '/core/reports/awbs',
  //       queryParams: {
  //         page: 0,
  //         size: 500
  //       },
  //       activeLinks: [],
  //       children: [],
  //       roles: [UserRole.ADMIN]
  //     },
  //     {
  //       id: 'customers-billable',
  //       key: 'admin.reports.billable-customers.nav',
  //       icon: 'receipt',
  //       link: '/core/reports/customers',
  //       queryParams: {},
  //       activeLinks: [],
  //       children: [],
  //       roles: [UserRole.ADMIN]
  //     }
  //   ]
  // },
  {
    id: 'contact',
    key: 'admin.contact.nav',
    icon: 'email',
    link: '/core/contact-messages',
    queryParams: {},
    activeLinks: [
      '/core/contact-messages/:id/reply'
    ],
    children: [],
    roles: [UserRole.ADMIN]
  },
  {
    id: 'users',
    key: 'admin.account.nav',
    icon: 'person',
    link: '/core/users',
    queryParams: {
      page: 0,
      size: 10
    },
    activeLinks: [
      '/core/users/new',
      '/core/users/:userId'
    ],
    children: [],
    roles: [UserRole.ADMIN]
  },
  {
    id: 'settings',
    key: 'admin.settings.title',
    icon: 'settings',
    link: '/core/settings',
    queryParams: {},
    activeLinks: [],
    roles: [UserRole.ADMIN],
    children: [
      {
        id: 'exchange-rate',
        key: 'admin.settings.exchange-rate.title',
        icon: 'price_change',
        link: '/core/settings/exchange-rate',
        queryParams: {},
        activeLinks: [],
        children: [],
        roles: [UserRole.ADMIN]
      }
    ]
  }
];