import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLPattern } from './url-pattern';

@Injectable({
  providedIn: 'root',
})
export class AuthExcludedInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (URLPattern.isPublic(request)) {
      const newReq = request.clone({ headers: request.headers.delete('Authorization') });
      return next.handle(newReq);
    } else {
      return next.handle(request);
    }
  }
}
