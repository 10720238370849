import { Component, OnInit } from '@angular/core';
import { UserRole } from 'src/app/shared/model/user-role';
import { User, UserForm } from 'src/app/shared/model/user';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormHelper } from 'src/app/shared/services/common/form-helper';
import { ToastService } from 'src/app/shared/services/common/toast.service';
import { UserService } from 'src/app/features/users/services/user.service';
import { UserValidators } from 'src/app/features/users/validators/user-validators';
import { switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/common/auth.service';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {

  readonly UserRole = UserRole;
  readonly userRoles = Object.keys(UserRole);

  user: User;
  userForm: FormGroup<UserForm>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly formBuilder: FormBuilder,
    private readonly formHelper: FormHelper,
    private readonly userService: UserService,
    private readonly toastService: ToastService) { }

  ngOnInit(): void {
    this.authService.getUsername()
      .pipe(
        switchMap(username => this.userService.findByUsername(username)),
        tap(user => this.user = user),
        tap(() => this.userForm = this.resetUserForm(this.user))
      )
      .subscribe();
  }

  save(): void {
    FormHelper.markAsDirty(this.userForm);
    if (this.userForm.valid) {
      const user: User = this.userForm.value as User;
      this.userService.update(this.user.uuid, user)
        .pipe(tap(() => this.toastService.showSuccessNotification()))
        .subscribe(user => this.user = user);
    } else {
      this.formHelper.focusFirstErrorField();
    }
  }

  reset(): void {
    this.resetUserForm(this.user);
  }

  private resetUserForm(user: User): FormGroup {
    return this.formBuilder.group({
      firstName: [user.firstName, Validators.required],
      lastName: [user.lastName, Validators.required],
      phone: [user.phone, Validators.required],
      email: [user.email, [Validators.required, CustomValidators.email()], [UserValidators.emailUniqueValidator(this.userService, user.uuid)]],
      username: [user.username, [Validators.required], [UserValidators.usernameUniqueValidator(this.userService, user.uuid)]],
      password: [],
      confirmPassword: [],
      barcode: [user.barcode],
      active: [user.active, Validators.required],
      role: [user.role, Validators.required]
    });
  }

}
