<div class="page-container">
  <div class="page-header">
    <span>{{ 'admin.user.settings.title' | translate }}</span>
  </div>
  <hr>
  <div>
    <form *ngIf="userForm"
          class="form-container"
          [formGroup]="userForm"
          (ngSubmit)="save()">
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-10 col-lg-8">
          <mat-card>
            <div>
              <mat-card-title>{{ 'admin.user.settings.title' | translate }}</mat-card-title>
              <mat-divider></mat-divider>
              <mat-card-content>
                <div class="row">
                  <div class="col">
                    <mat-form-field color="primary">
                      <mat-label>{{ 'admin.user.settings.form.first-name' | translate }}</mat-label>
                      <input matInput
                             placeholder="{{ 'admin.user.settings.form.first-name' | translate }}"
                             formControlName="firstName"
                             autocomplete="off">
                      <mat-error matErrorMessage></mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field color="primary">
                      <mat-label>{{ 'admin.user.settings.form.last-name' | translate }}</mat-label>
                      <input matInput
                             placeholder="{{ 'admin.user.settings.form.last-name' | translate }}"
                             formControlName="lastName"
                             autocomplete="off">
                      <mat-error matErrorMessage></mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field color="primary">
                      <mat-label>{{ 'admin.user.settings.form.phone' | translate }}</mat-label>
                      <input matInput
                             placeholder="{{ 'admin.user.settings.form.phone' | translate }}"
                             formControlName="phone"
                             autocomplete="off">
                      <mat-error matErrorMessage></mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field color="primary">
                      <mat-label>{{ 'admin.user.settings.form.email' | translate }}</mat-label>
                      <input matInput
                             type="email"
                             placeholder="{{ 'admin.user.settings.form.email' | translate }}"
                             formControlName="email"
                             autocomplete="off">
                      <mat-error matErrorMessage></mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-form-field color="primary">
                      <mat-label>{{ 'admin.user.settings.form.username' | translate }}</mat-label>
                      <input matInput
                             placeholder="{{ 'admin.user.settings.form.username' | translate }}"
                             formControlName="username"
                             autocomplete="off">
                      <mat-error matErrorMessage></mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <mat-divider></mat-divider>
                <div class="row">
                  <div class="col">
                    <div class="form-actions">
                      <button mat-raised-button
                              color="primary"
                              type="submit">
                        {{ 'global.buttons.save' | translate }}
                      </button>
                      <button mat-stroked-button
                              color="primary"
                              type="button"
                              (click)="reset()">
                        {{ 'global.buttons.reset' | translate }}
                      </button>
                      <button mat-stroked-button
                              color="primary"
                              type="button"
                              routerLink="/core/account/password/change">
                        {{ 'admin.user.settings.buttons.change-password' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </div>
          </mat-card>
        </div>
      </div>
    </form>
  </div>
</div>