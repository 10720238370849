import {MatDateFormats} from '@angular/material/core';

export class DateFormatConstants {
  static readonly MONTH_DAY_YEAR_SLASH_SEPARATED = 'MM/DD/YYYY';
  static readonly DATE_TIME_WITH_SECONDS = 'EEE, dd MMM yyyy, HH:mm:ss';

  static getDefaultFormat(): MatDateFormats {
    return {
      parse: {
        dateInput: 'DD/MM/YYYY',
      },
      display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    };
  }
}
