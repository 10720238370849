<div class="page-container">
  <div class="page-header">
    <span>{{ 'admin.user.password-change.title' | translate }}</span>
  </div>
  <hr>
  <div>
    <form *ngIf="passwordChangeForm"
          class="form-container"
          [formGroup]="passwordChangeForm"
          (ngSubmit)="save()">
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-10 col-lg-8">
          <mat-card>
            <mat-card-title>{{ 'admin.user.password-change.title' | translate }}</mat-card-title>
            <mat-divider></mat-divider>
            <div class="row">
              <div class="col">
                <mat-form-field color="primary">
                  <mat-label>{{ 'admin.user.password-change.form.old-password' | translate }}</mat-label>
                  <input matInput
                         type="password"
                         placeholder="{{ 'admin.user.password-change.form.old-password' | translate }}"
                         formControlName="oldPassword"
                         autocomplete="off">
                  <mat-error matErrorMessage></mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field color="primary">
                  <mat-label>{{ 'admin.user.password-change.form.password' | translate }}</mat-label>
                  <input matInput
                         type="password"
                         placeholder="{{ 'admin.user.password-change.form.password' | translate }}"
                         formControlName="password"
                         autocomplete="off">
                  <mat-error matErrorMessage></mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field color="primary">
                  <mat-label>{{ 'admin.user.password-change.form.confirm-password' | translate }}</mat-label>
                  <input matInput
                         type="password"
                         placeholder="{{ 'admin.user.password-change.form.confirm-password' | translate }}"
                         formControlName="confirmPassword"
                         autocomplete="off">
                  <mat-error matErrorMessage></mat-error>
                </mat-form-field>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="row">
              <div class="col">
                <div class="form-actions">
                  <button mat-raised-button
                          color="primary"
                          type="submit">
                    {{ 'global.buttons.save' | translate }}
                  </button>
                  <button mat-stroked-button
                          color="primary"
                          type="button"
                          (click)="reset()">
                    {{ 'global.buttons.reset' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </form>
  </div>
</div>