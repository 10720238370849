import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggerModule } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { CoreRoutingModule } from './core-routing.module';
import { CoreMenuComponent } from './template/menu/core-menu.component';
import { SharedModule } from '../shared/shared.module';
import { CoreComponent } from './core.component';
import { AccountComponent } from './account/account.component';
import { AccountSettingsComponent } from './account/settings/account-settings.component';
import { PasswordChangeComponent } from './account/password-change/password-change.component';
import { QRCodeDialogComponent } from '../shared/components/dialogs/qrcode-dialog/qrcode-dialog.component';
import { BarcodeDialogComponent } from '../shared/components/dialogs/barcode-dialog/barcode-dialog.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { QRCodeModule } from 'angularx-qrcode';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AngularMaterialModule } from '../shared/angular-material.module';
import { NotificationsComponent } from './components/notifications/notifications.component';

@NgModule({
  declarations: [
    CoreComponent,
    CoreMenuComponent,
    AccountComponent,
    AccountSettingsComponent,
    PasswordChangeComponent,
    QRCodeDialogComponent,
    BarcodeDialogComponent,
    BreadcrumbComponent,
    NotificationsComponent
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    SharedModule,
    QRCodeModule,
    NgxBarcode6Module,
    LoggerModule.forRoot({
      serverLoggingUrl: `${environment.baseUrl}/api/ng-logs`,
      level: environment.logLevel,
      serverLogLevel: environment.logLevel
    }),
    AngularMaterialModule
  ],
  providers: []
})
export class CoreModule { }
