import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/components/auth.guard';
import { UserRole } from '../shared/model/user-role';
import { AccountComponent } from './account/account.component';
import { PasswordChangeComponent } from './account/password-change/password-change.component';
import { AccountSettingsComponent } from './account/settings/account-settings.component';
import { CoreComponent } from './core.component';

const routes: Routes = [
  {
    path: '',
    component: CoreComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.ADMIN, UserRole.CUSTOMER] },
    children: [
      { path: 'awbs', loadChildren: () => import('../features/awbs/awbs.module').then(m => m.AwbsModule) },
      { path: 'reports', loadChildren: () => import('../features/reports/reports.module').then(m => m.ReportsModule) },
      { path: 'address-books', loadChildren: () => import('../features/address-books/address-books.module').then(m => m.AddressBooksModule) },
      { path: 'customers', loadChildren: () => import('../features/customers/customers.module').then(m => m.CustomersModule) },
      { path: 'users', loadChildren: () => import('../features/users/users.module').then(m => m.UsersModule) },
      { path: 'settings', loadChildren: () => import('../features/settings/settings.module').then(m => m.SettingsModule) },
      { path: 'contact-messages', loadChildren: () => import('../features/contact-messages/contact-messages.module').then(m => m.ContactMessagesModule) },
      { path: 'settings', loadChildren: () => import('../features/settings/settings.module').then(m => m.SettingsModule) },
      {
        path: 'account',
        component: AccountComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [UserRole.ADMIN, UserRole.CUSTOMER],
          title: 'admin.user.settings.title'
        },
        children: [
          {
            path: 'settings',
            component: AccountSettingsComponent,
            canActivate: [AuthGuard],
            data: {
              roles: [UserRole.ADMIN, UserRole.CUSTOMER]
            }
          },
          {
            path: 'password/change',
            component: PasswordChangeComponent,
            canActivate: [AuthGuard],
            data: {
              roles: [UserRole.ADMIN, UserRole.CUSTOMER]
            }
          }]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CoreRoutingModule { }
