import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MatPaginatorIntlLocalized extends MatPaginatorIntl {

  constructor(private translateService: TranslateService) {
    super();
    this.init();
  }

  private init(): void {
    this.translateLabels();
    this.translateService.onLangChange.subscribe(() => this.translateLabels());
  }

  private translateLabels(): void {
    this.itemsPerPageLabel = this.translateService.instant('components.paginator.items-per-page');
    this.nextPageLabel = this.translateService.instant('components.paginator.next-page');
    this.previousPageLabel = this.translateService.instant('components.paginator.previous-page');
    this.firstPageLabel = this.translateService.instant('components.paginator.first-page');
    this.lastPageLabel = this.translateService.instant('components.paginator.last-page');
    this.changes.next();
  }
}
