import { Injectable } from '@angular/core';
import { Observable, map, filter, switchMap, of, distinctUntilChanged, tap, ReplaySubject, BehaviorSubject, combineLatest } from 'rxjs';
import { UserRole } from 'src/app/shared/model/user-role';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { ConfigService } from '../http/config.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private readonly isAuthenticatedState = new ReplaySubject<boolean>();

  constructor(
    private readonly configService: ConfigService,
    private readonly oauthService: OAuthService,
    private readonly logger: NGXLogger) {
  }

  public isAuthenticated$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = new ReplaySubject<boolean>();

  // This is used in non-forced-login guard, see auth-guard.service.ts
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticatedState,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(value => value)));

  get authenticated(): boolean {
    return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
  }

  get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  get claims(): Record<string, any> {
    return this.oauthService.getIdentityClaims();
  }

  configure(): Observable<void> {
    return this.configService.getOidcConfig()
      .pipe(
        map(oidcConfig => {
          const authCodeFlowConfig: AuthConfig = {
            issuer: oidcConfig.authServer,
            redirectUri: oidcConfig.redirectUrl,
            postLogoutRedirectUri: window.location.origin,
            requireHttps: oidcConfig.requireHttps,
            clearHashAfterLogin: false,
            useSilentRefresh: false,
            clientId: 'bauexpress-spa',
            responseType: 'code',
            scope: 'openid profile email offline_access roles',
            showDebugInformation: true
          };
          return authCodeFlowConfig;
        }),
        tap(authConfig => this.oauthService.configure(authConfig)),
        tap(() => this.oauthService.setupAutomaticSilentRefresh()),
        switchMap(() => this.oauthService.loadDiscoveryDocument()),
        map(() => void 0)
      );
  }

  refresh(): void {
    this.isAuthenticatedState.next(this.authenticated);
  }

  isAdmin(): Observable<boolean> {
    return this.getRoles().pipe(map(roles => roles.includes(UserRole.ADMIN)));
  }

  isCustomer(): Observable<boolean> {
    return this.getRoles().pipe(map(roles => roles.includes(UserRole.CUSTOMER)));
  }

  getRoles(): Observable<string[]> {
    return of(this.claims['realm_access']['roles']);
  }

  getUsername(): Observable<string> {
    return of(this.claims['preferred_username']);
  }

  getFullName(): Observable<string> {
    return of(this.claims['name']);
  }

  isAuthenticated(): Observable<boolean> {
    return of(this.authenticated);
  }

  getAccessToken(): Observable<string> {
    return of(this.accessToken);
  }

  login(): void {
    this.oauthService.initLoginFlow();
  }

  logout(): Observable<void> {
    this.oauthService.revokeTokenAndLogout();
    this.isAuthenticatedState.next(false);
    return of();
  }

  onAuthenticate(): Observable<boolean> {
    return this.isAuthenticatedState.asObservable()
      .pipe(
        tap(authenticated => this.logger.debug(`Authenticated: ${authenticated}`)),
        distinctUntilChanged()
      );
  }

  onLogin(): Observable<string> {
    return this.onAuthenticate()
      .pipe(
        filter(authenticated => authenticated),
        switchMap(() => this.getUsername())
      );
  }

  onLogout(): Observable<void> {
    return this.onAuthenticate()
      .pipe(
        filter(authenticated => !authenticated),
        map(() => void 0)
      );
  }

}
