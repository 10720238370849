import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { filter, map, noop, Observable, of, switchMap, tap } from 'rxjs';
import { UserRole } from '../model/user-role';
import { AuthService } from '../services/common/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {

  constructor(
    private readonly logger: NGXLogger,
    private readonly authService: AuthService,
    private readonly router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.canActivateProtectedRoutes$
      .pipe(
        tap(canActivateProtectedRoutes => this.logger.debug(`Auth guard: ${state.url}, canActivateProtectedRoutes: ${canActivateProtectedRoutes}, authenticated: ${this.authService.authenticated}`)),
        map(canActivateProtectedRoutes => canActivateProtectedRoutes && this.authService.authenticated),
        tap(isAuthenticated => isAuthenticated ? noop() : this.authService.login()),
        filter(isAuthenticated => isAuthenticated),
        switchMap(() => this.authService.getRoles()),
        tap(roles => this.logger.debug(`Auth guard: ${state.url}, roles: ${JSON.stringify(roles)}`)),
        switchMap(roles => of(this.hasRequiredRoles(route, roles)))
      );
  }

  private hasRequiredRoles(route: ActivatedRouteSnapshot, roles: string[]): boolean {
    const requiredRoles = route.data['roles'] as UserRole[];
    if (!requiredRoles || requiredRoles.length === 0) {
      return true;
    } else {
      if (!roles || roles.length === 0) {
        return false;
      }
      return requiredRoles.some(role => roles.includes(role));
    }
  }
}