import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Notification } from 'src/app/shared/model/notifications/notification';
import { PageResult } from '../../model/page/page-result';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  constructor(private readonly http: HttpClient) { }

  getNotifications(): Observable<PageResult<Notification<any>>> {
    return this.http.get<PageResult<Notification<any>>>('api/notifications');
  }

  markRead(uuid: string): Observable<void> {
    return this.http.put<void>(`api/notifications/${uuid}/read`, {});
  }
}
