import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { finalize, tap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {

  constructor(
    private readonly logger: NGXLogger,
    private readonly authService: AuthService) { }

  error(message: string): void {
    this.authService.getAccessToken()
      .pipe(
        tap(token => {
          const updatedConfig = this.logger.getConfigSnapshot();
          updatedConfig.customHttpHeaders = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
          this.logger.updateConfig(updatedConfig);
        }),
        finalize(() => this.logger.error(message))
      )
      .subscribe();
  }
}
