import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap, combineLatest, switchMap } from 'rxjs';
import { AwbStatusNotification } from 'src/app/shared/model/notifications/awb-status-notification';
import { PageResult } from 'src/app/shared/model/page/page-result';
import { AuthService } from 'src/app/shared/services/common/auth.service';
import { NotificationService } from 'src/app/shared/services/http/notification.service';
import { WebSocketService } from 'src/app/shared/services/websocket/websocket.service';
import { Notification } from 'src/app/shared/model/notifications/notification';
import { NotificationType } from 'src/app/shared/model/notifications/notification-type';
import { ConfigService } from 'src/app/shared/services/http/config.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
@UntilDestroy()
export class NotificationsComponent implements OnInit {

  readonly NotificationType = NotificationType;

  @Input() notifications: Notification<any>[] = [];
  @Output() notificationsChange: EventEmitter<Notification<any>[]> = new EventEmitter<Notification<any>[]>();

  constructor(
    private readonly notificationService: NotificationService,
    private readonly authService: AuthService,
    private readonly configService: ConfigService,
    private readonly webSocketService: WebSocketService) {
  }

  ngOnInit(): void {
    this.resetData();
    this.initWebSocket();
    this.authService.onLogout().subscribe(() => this.webSocketService.stop());
  }

  private initWebSocket(): void {
    combineLatest([
      this.authService.onLogin(),
      this.configService.getWebsocketUrl()
    ])
      .pipe(
        untilDestroyed(this),
        tap(([_, url]) => this.webSocketService.configure(url)),
        tap(() => this.webSocketService.activate()),
        switchMap(() => this.webSocketService.watch<Notification<any>>('/user/queue/notifications')
          .pipe(
            untilDestroyed(this),
            tap(notifcation => this.notifications.unshift(notifcation)),
            tap(() => this.notificationsChange.emit(this.notifications)),
          ))
      )
      .subscribe();
  }

  private resetData(): void {
    this.notificationService
      .getNotifications()
      .pipe(
        tap((notifications: PageResult<Notification<any>>) => this.notifications = notifications.content),
        tap(() => this.notificationsChange.emit(this.notifications))
      )
      .subscribe();
  }

  markRead(notification: AwbStatusNotification): void {
    this.notificationService
      .markRead(notification.uuid)
      .subscribe(() => this.resetData());
  }
}
