import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APIInterceptor } from './api-interceptor';
import { ErrorInterceptor } from './error-interceptor';
import { CacheBusterInterceptor } from './cache-buster-interceptor';
import { HttpXsrfInterceptor } from './xsrf-interceptor';
import { AuthExcludedInterceptor } from './auth-excluded-interceptor';
import { AuthInterceptor } from './auth.interceptor';

export const interceptorProviders =
  [
    { provide: HTTP_INTERCEPTORS, useClass: AuthExcludedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheBusterInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true }
  ];
