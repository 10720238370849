export enum Theme {
  GREEN = 'GREEN',
  BLUE = 'BLUE'
}

export namespace Theme {
  export function themeClass(theme: Theme): string {
    switch (theme) {
      case Theme.BLUE: return 'blue-theme';
      case Theme.GREEN: return 'green-theme';
    }
  }
}