import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs';
import { UserRole } from 'src/app/shared/model/user-role';
import { AuthService } from 'src/app/shared/services/common/auth.service';
import { menu } from './_menus';
import { ThemeService } from 'src/app/shared/theme/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { Theme } from 'src/app/shared/theme/theme';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationService } from 'src/app/shared/services/http/notification.service';
import { Notification } from 'src/app/shared/model/notifications/notification';
import { PageResult } from 'src/app/shared/model/page/page-result';

@Component({
  selector: 'app-core-menu',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss']
})
@UntilDestroy()
export class CoreMenuComponent implements OnInit {
  readonly Theme = Theme;

  menu: MenuItem[] = [];
  authenticatedUser: string;
  publicUrl: string;
  isMobile = false;
  notifications: Notification<any>[] = [];

  @ViewChild('menuSideNav') menuSideNav: MatSidenav;
  @ViewChild('notificationSideNav') notificationSideNav: MatSidenav;

  @ViewChild('notificationSideNav', { read: ElementRef }) notificationSideNavElementRef: ElementRef;
  @ViewChild('notificationMenu', { read: ElementRef }) notificationMenuElementRef: ElementRef;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    private readonly themeService: ThemeService,
    private readonly breakpointObserver: BreakpointObserver) {
    this.isMobile = this.breakpointObserver.isMatched(['(max-width: 800px)']);
    this.breakpointObserver.observe(['(max-width: 800px)'])
      .subscribe(result => this.isMobile = result.matches);
    this.closeMenuOnMobileClick();
  }

  ngOnInit(): void {
    this.initializeData();
    this.initializeMenu();
  }

  @HostListener('click', ['$event.target'])
  onClick(target: any) {
    setTimeout(() => {
      const shouldCloseNotificationSideNav = this.notificationSideNav?.opened
        && !this.notificationSideNavElementRef.nativeElement?.contains(target)
        && !this.notificationMenuElementRef.nativeElement?.contains(target);
      if (shouldCloseNotificationSideNav) {
        this.notificationSideNav.close();
      }
    });
  }

  private initializeData(): void {
    this.authService.getFullName().subscribe(authenticatedUser => this.authenticatedUser = authenticatedUser);
    this.notificationService
      .getNotifications()
      .subscribe((notifications: PageResult<Notification<any>>) => this.notifications = notifications.content);
  }

  private initializeMenu(): void {
    this.authService.getRoles()
      .pipe(
        tap(roles => this.menu = menu.filter(menuItem => this.isAvailable(menuItem, roles as UserRole[]))))
      .subscribe();
  }

  isActive(menuItem: MenuItem): boolean {
    const routeWithoutQueryParams = this.router.url.split('?')[0];
    return menuItem.link === routeWithoutQueryParams
      || menuItem.activeLinks.includes(routeWithoutQueryParams)
      || menuItem.children.some(subMenuItem => this.isActive(subMenuItem));
  }

  logout(): void {
    this.authService.logout().subscribe(() => this.router.navigate(['']));
  }

  switchLanguage(language: string): void {
    this.translateService.use(language);
  }

  switchTheme(theme: Theme): void {
    this.themeService.setTheme(theme);
  }

  private closeMenuOnMobileClick(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(() => this.menuSideNav != null),
        filter(() => this.isMobile),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(event => this.menuSideNav.close());
  }

  private isAvailable(menuItem: MenuItem, roles: UserRole[]): boolean {
    return roles.some(role => menuItem.roles.map(menuRole => menuRole).includes(role));
  }

}

interface MenuItem {
  id: string;
  key: string;
  link: string;
  queryParams: any,
  icon: string;
  activeLinks: string[];
  children: MenuItem[];
  roles: UserRole[];
}
