import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { AuthService } from 'src/app/shared/services/common/auth.service';
import { SecuredApiInterceptor } from './secured-api-interceptor';

@Injectable()
export class AuthInterceptor extends SecuredApiInterceptor {

  constructor(private readonly authService: AuthService) {
    super();
  }

  doIntercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(response => this.handleTokenRequestError(response)));
  }

  private handleTokenRequestError(response: HttpErrorResponse): Observable<never> {
    if (response.status === 401) {
      this.authService.login();
    }
    return throwError(response);
  }
}
