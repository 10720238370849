import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class FormHelper {

  static markAsDirty(form: FormGroup): void {
    form.markAsDirty();
    if (form.controls) {
      for (const control of Object.keys(form.controls)) {
        const formControls = (form.controls[control] as FormGroup).controls;
        if (!formControls) {
          form.controls[control].markAsDirty();
        } else {
          const formArray = (form.controls[control] as FormArray).controls;
          if (Array.isArray(formArray)) {
            formArray.forEach(formGroup => FormHelper.markAsDirty(formGroup as FormGroup));
          } else {
            for (const abstractControl of Object.keys(formControls)) {
              FormHelper.markAsDirty(formControls[abstractControl] as FormGroup);
            }
          }
        }
      }
    }
  }

  static resetArrayCollections(array: FormArray): void {
    for (let i = array.length - 1; i >= 0; i--) {
      array.removeAt(i);
    }
  }

  focusFirstErrorField(): void {
    setTimeout(() => {
      const invalidElements = $('input.ng-invalid, textarea.ng-invalid, select.ng-invalid, mat-select.ng-invalid');
      if (invalidElements.length > 0) {
        const firstInvalidElement = invalidElements[0];
        const innerInputElements = $(firstInvalidElement).find('input');
        const targetElement = (innerInputElements.length === 0 || !innerInputElements)
          ? firstInvalidElement
          : innerInputElements[0];

        targetElement.focus();
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }
  //
  // focusFirstAmountInputField(): void {
  //   setTimeout(() => {
  //     const formControls = this.$('et-amount-input');
  //     if (formControls.length > 0) {
  //       const firstElement = formControls[0];
  //       const innerInputElements = this.$(firstElement).find('input.et-input, input');
  //       const targetElement = (!innerInputElements || innerInputElements.length === 0)
  //         ? firstElement
  //         : innerInputElements[0];
  //
  //       targetElement.focus();
  //     }
  //   });
  // }
}
