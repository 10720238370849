import { HttpRequest } from "@angular/common/http";

export class URLPattern {

  private static readonly PUBLIC_URLS = [
    { method: 'GET', pattern: 'api-docs' },
    { method: 'GET', pattern: 'api/config/oidc' },
    { method: 'GET', pattern: 'api/config/public-url' },
    // { method: 'GET', pattern: 'api/countries/{code}' },
    // { method: 'GET', pattern: 'api/countries/{countryCode}/counties' },
    // { method: 'GET', pattern: 'api/countries/{countryCode}/localities' },
    { method: 'GET', pattern: 'api/awbs/trackings' },
    { method: 'GET', pattern: 'api/couriers' },
    { method: 'POST', pattern: 'api/shipping/quotes' },
    { method: 'POST', pattern: 'api/contact-messages' }
  ];

  constructor(public pattern: string, public method: string) { }

  static isPublic(request: HttpRequest<any>): boolean {
    return !URLPattern.isProtected(request);
  }

  static isProtected(request: HttpRequest<any>): boolean {
    return request.url.startsWith('api') && !URLPattern.PUBLIC_URLS.some(url => this.match(request, url));
  }

  private static match(request: HttpRequest<any>, urlPattern: URLPattern): boolean {
    return request.url.includes(urlPattern.pattern) && request.method === urlPattern.method;
  }
}